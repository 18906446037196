import {ListItemText} from "@mui/material";
import React from "react";
import {NavigationItemProps, NavListItem} from "@components/side-nav/tree-nav/side-nav";
import {NavigationInternalLinkData} from "@lib/nav/link";
import {isParentOrEqual} from "@lib/utils/url";
import Link from 'next/link'
import {useSideNav} from "@lib/hooks/use-side-nav";
import {SideNavIcon} from "@components/side-nav/tree-nav/side-nav-icon";

export const InternalLinkItem = ({entry, currentSlug, level}: NavigationItemProps<NavigationInternalLinkData>) => {
    const [_, setSideNavOpen] = useSideNav()
    const hideSideNav = () => {
        setSideNavOpen(false)
    }
    return (
        // <Link
        //     href={entry.fullSlug}
        //     passHref
        // >
        <Link href={entry.fullSlug} passHref>
            <NavListItem
                component='a'
                onClick={hideSideNav}
                // component={({innerRef, ...props})  => <GenericLink ref={innerRef as unknown as ForwardedRef<HTMLSpanElement>} {...props} href={entry.fullSlug} onClick={(_: unknown) => setSideNavOpen(false)}/>}
                selected={isParentOrEqual(entry.fullSlug, currentSlug)}
                level={level}
            >
                <SideNavIcon entry={entry}/>
                <ListItemText primaryTypographyProps={{variant: 'body2'}}>
                    {entry.label}
                </ListItemText>
            </NavListItem>
        </Link>
    )
}