import {Theme} from "@mui/material/styles";
import {typographyMixin} from "@lib/styles/theme-utils";
import {Variant} from "@lib/styles/types";
import {grey} from "@mui/material/colors";

export const PARAGRAPH_SPACING = 2
export const BLOCK_SPACING = 2
export const SECTION_SPACING = 8

export const marginYMixin = (theme: Theme, spacing: number) => ({
    marginTop: theme.spacing(spacing),
    marginBottom: theme.spacing(spacing),
})

export const headerMixin = (theme: Theme, variant: Variant) => ({
    textAlign: 'center',
    ...marginYMixin(theme, BLOCK_SPACING),
    ...responsiveWidthMixin(theme),
    ...typographyMixin(theme, variant),
})

export const blockMixin = (theme: Theme) => ({
    ...marginYMixin(theme, BLOCK_SPACING),
    [theme.breakpoints.up('sm')]: {
        borderRadius: theme.shape.borderRadius,
    },
})

export const buttonMixin = (theme: Theme, color: "inherit" | "primary" | "secondary" = 'primary') => ({
    display: 'inline-flex',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    ...typographyMixin(theme, 'button'),
    color: color === 'inherit' ? 'inherit' : theme.palette[color].contrastText,
    backgroundColor: color === 'inherit' ? 'inherit' : theme.palette[color].main,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(0.8),
    paddingBottom: theme.spacing(0.8),
    border: 'none',
    borderRadius: theme.shape.borderRadius,
    transition: theme.transitions.create(['background-color']),
    cursor: 'pointer',
    '&:hover': {
        backgroundColor: color === 'inherit' ? theme.palette.action.hover : theme.palette.primary.dark,
    },
    '&:focus': {
        outline: 'none',
        backgroundColor: color === 'inherit' ? theme.palette.action.focus : theme.palette.primary.dark,
    },
})

export const responsiveWidthMixin = (theme: Theme) => ({
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
        marginLeft: 0,
        marginRight: 0,
    },
})

export const copyToClipBoardContainerClass = 'copyToClipBoardContainer'
export const getCodeColor = (theme: Theme) => theme.palette.mode === 'light' ? grey.A400 : grey.A700