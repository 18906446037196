import React from 'react'
import {Container, Paper} from "@mui/material";
import classNames from "classnames";

const ARTICLE_WIDTH = '760px'

export const ArticleContainer = ({children, className}: { children: React.ReactNode, className?: string }) => (
    <Container maxWidth={false} sx={{px: 0, py: 4, maxWidth: ARTICLE_WIDTH}}>
        <Paper
            component='article'
            elevation={0}
            sx={{position: 'relative'}}
            className={classNames('DocSearch-content', className)}
        >
            {children}
        </Paper>
    </Container>
)