import {BASE_URL, ENVIRONMENT} from "@lib/environment";
import {getAppBarColor} from "@lib/styles/theme-utils";
import {defaultTheme} from "@lib/styles/themes";

export const SITE_URL = BASE_URL
export const BRAND_NAME = 'Pricefx'
export const SITE_NAME = 'Pricefx Developer Portal'
export const SITE_SHORT_NAME = ENVIRONMENT === 'development' ? 'Dev' : 'Pricefx Developer'
export const SITE_DEFAULT_THEME_COLOR = getAppBarColor(defaultTheme)
export const SITE_DESCRIPTION = 'The Pricefx Developer Portal contains documentation and other resources for development on the Pricefx platform.'
export const DATE = 'April 20th, 2021';
export const COOKIE = 'user-id';

export const GIT_REPOSITORY_URL = 'https://gitlab.pricefx.eu/training/pricefx-knowledge-base'
export const EDIT_URL_BASE = `${GIT_REPOSITORY_URL}/-/tree/dev`
