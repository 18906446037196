import React from "react";
import {Button, SxProps} from "@mui/material";
import Brightness7 from "@mui/icons-material/Brightness7";
import Brightness4 from "@mui/icons-material/Brightness4";
import {Theme} from "@mui/system";
import useDarkMode from "use-dark-mode";

const ThemeSwitch = ({sx}: {sx?: SxProps<Theme>}) => {
    const {value: isDark, toggle} = useDarkMode()
    return (
        <Button
            aria-label="switch-dark-light-theme"
            onClick={toggle}
            sx={sx}
        >
            {isDark ? <Brightness7/> : <Brightness4/> }
        </Button>
    )
}

export default ThemeSwitch