import Head from 'next/head';
import {useRouter} from 'next/router';
import {BRAND_NAME, SITE_DESCRIPTION, SITE_NAME, SITE_URL} from '@lib/configuration';
import {PageMeta} from "@lib/page-meta";
import React from "react";
import {useTheme} from "@mui/material/styles";
import {getAppBarColor} from "@lib/styles/theme-utils";
import {LayoutComponent} from "@lib/content-component/layout-props";

type Props = {
    children?: React.ReactNode
    Layout?: LayoutComponent // TODO use this
} & PageMeta

export default function Page({name, description, children, Layout}: Props) {
    const router = useRouter();

    const pageName = typeof name === 'string' ? `${BRAND_NAME} | ${name}` : SITE_NAME
    const pageDescription = description ?? SITE_DESCRIPTION
    const imageUrl = '/icons/fx/icon-512x512.png'
    const fullUrl = `${SITE_URL}${router.asPath}`

    const theme = useTheme()

    return (
        <div id='page'>
            <Head>
                <title>{pageName}</title>
                <meta name="description" content={pageDescription}/>
                {/* Apple Icons */}
                <link rel="apple-touch-icon" sizes="57x57" href="/icons/fx/unity-primary/apple-icon-57x57.png"/>
                <link rel="apple-touch-icon" sizes="60x60" href="/icons/fx/unity-primary/apple-icon-60x60.png"/>
                <link rel="apple-touch-icon" sizes="72x72" href="/icons/fx/unity-primary/apple-icon-72x72.png"/>
                <link rel="apple-touch-icon" sizes="76x76" href="/icons/fx/unity-primary/apple-icon-76x76.png"/>
                <link rel="apple-touch-icon" sizes="114x114" href="/icons/fx/unity-primary/apple-icon-114x114.png"/>
                <link rel="apple-touch-icon" sizes="120x120" href="/icons/fx/unity-primary/apple-icon-120x120.png"/>
                <link rel="apple-touch-icon" sizes="144x144" href="/icons/fx/unity-primary/apple-icon-144x144.png"/>
                <link rel="apple-touch-icon" sizes="152x152" href="/icons/fx/unity-primary/apple-icon-152x152.png"/>
                <link rel="apple-touch-icon" sizes="180x180" href="/icons/fx/unity-primary/apple-icon-180x180.png"/>
                {/* Android Icons */}
                <link rel="icon" type="image/png" sizes="192x192"  href="/icons/fx/unity-primary/android-icon-192x192.png" />
                {/* Microsoft Icons */}
                <meta name="msapplication-TileImage" content="/icons/fx/unity-primary/ms-icon-144x144.png" />
                {/* Icons */}
                <link rel="icon" type="image/png" sizes="32x32" href="/icons/fx/unity-primary/icon-32x32.png"/>
                <link rel="icon" type="image/png" sizes="96x96" href="/icons/fx/unity-primary/icon-96x96.png" />
                <link rel="icon" type="image/png" sizes="16x16" href="/icons/fx/unity-primary/icon-16x16.png"/>
                {/* PWA */}
                <link rel="manifest" href="/site.webmanifest"/>
                {/* Bookmark Icon */}
                <link rel="shortcut icon" href="/icons/fx/unity-primary/icon-32x32.png"/>
                {/* Theme color */}
                <meta name="msapplication-TileColor" content={getAppBarColor(theme)} />
                <meta name="theme-color" content={getAppBarColor(theme)}/>
                {/* Link Preview */}
                <meta property="og:image" content={`${SITE_URL}${imageUrl}`}/>
                <meta property="og:title" content={pageName}/>
                <meta property="og:url" content={fullUrl}/>
                <meta property="og:description" content={pageDescription}/>
            </Head>
            <div key='content' id='#content'>
                {children}
            </div>
        </div>
    );
}
