import {isParentOrEqual} from "@lib/utils/url";

export type NavLink = NavigationFolderData | NavigationExternalLinkData

export type NavigationItemData = NavigationFolderData | NavigationExternalLinkData | NavigationInternalLinkData
export type NavigationLinkData = NavigationExternalLinkData | NavigationInternalLinkData
export type NavigationInternalData = NavigationFolderData | NavigationInternalLinkData

export type NavigationEntryBase<T extends string> = {
    type: T
    order?: string
    label: string
    uid: string
}

export type WithSlug = {
    fullSlug: string
}

export type NavigationFolderData = NavigationEntryBase<'Folder'>  & WithSlug & {
    children: NavigationItemData[]
}

export type NavigationExternalLinkData = NavigationEntryBase<'ExternalLink'> & {
    href: string
}

export type NavigationInternalLinkData = NavigationEntryBase<'InternalLink'> & WithSlug


export const compareLinks = (a: NavigationItemData, b:NavigationItemData) => (a.order??'99999999999999999999999999').localeCompare(b.order ?? '99999999999999999999999999')

export interface NavigationData {
    navFolder: NavigationFolderData
    currentSlug: string
}

export const isInternal = (item: NavigationItemData): boolean => (
    item.type === 'Folder' || item.type === 'InternalLink'
)

const findInternalChildren = (navFolder: NavigationFolderData): NavigationInternalData[] => (
    navFolder.children.filter(isInternal) as NavigationInternalData[]
)

const findInternalLinks = (items: NavigationItemData[]): NavigationInternalLinkData[] => (
    items.filter(it => it.type === 'InternalLink') as NavigationInternalLinkData[]
)


export type NavigationSiblings = {
    previous?: NavigationInternalLinkData
    next?: NavigationInternalLinkData
}

export const findSiblings = (navFolder: NavigationFolderData, fullSlug: string): NavigationSiblings => {
    const internalChildren = findInternalChildren(navFolder)
    const matchingChild = internalChildren.find(child => isParentOrEqual(child.fullSlug, fullSlug))
    if (!matchingChild) {
        return {}
    }
    if (matchingChild.type === 'InternalLink') {
        const internalLinks = findInternalLinks(internalChildren).sort(compareLinks)
        const index = internalLinks.findIndex(item => item.uid === matchingChild.uid)
        return {
            previous: internalLinks[index - 1],
            next: internalLinks[index + 1],
        }
    } else {
        return findSiblings(matchingChild, fullSlug)
    }
}