import React from "react";
import {Collapse, ListItemText} from "@mui/material";
import { NavigationFolderData} from "@lib/nav/link";
import {
    NavigationItemProps, NavigationList, NavListItem
} from "@components/side-nav/tree-nav/side-nav";
import {isParentOrEqual} from "@lib/utils/url";
import Head from "next/head";
import {PricefxFacets} from "@lib/algolia/record-model";

import {SideNavIcon} from "@components/side-nav/tree-nav/side-nav-icon";

const getFacetAttribute = (level: number): keyof PricefxFacets | undefined => {
    switch (level) {
        case 0: return 'type'
        case 1: return 'topic'
        default: return undefined
    }
}

export const FolderItem = ({entry: folder, currentSlug, level}: NavigationItemProps<NavigationFolderData>) => {
    const [open, setOpen] = React.useState(isParentOrEqual(folder.fullSlug, currentSlug))
    const handleClick = () => {
        setOpen(!open);
    };


    const facetAttribute = getFacetAttribute(level)

    const isChildActive = isParentOrEqual(folder.fullSlug, currentSlug)

    return (
        <>
            {
                isChildActive && facetAttribute && (
                    <Head>
                        <meta name={`docsearch:${facetAttribute}`} content={folder.label} />
                    </Head>
                )
            }
            <NavListItem
                onClick={handleClick}
                selected={!open && isChildActive}
                level={level}
            >
                <SideNavIcon entry={folder}/>
                <ListItemText primary={folder.label} primaryTypographyProps={{variant: 'subtitle2'}} />
                {/*<ListItemIcon>*/}
                {/*    <ArrowRight fontSize='small' color="action" />*/}
                {/*</ListItemIcon>*/}
            </NavListItem>
            <Collapse
                in={open}
                timeout="auto"
                unmountOnExit
            >
                <NavigationList
                    // dense
                    entry={folder}
                    currentSlug={currentSlug}
                    level={level +1}
                />
            </Collapse>
        </>
    )
}
