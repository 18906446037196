import React from 'react'
import {Dispatch, SetStateAction, useEffect, useRef, useState} from "react";
import {Box, ListItem, ListItemText} from "@mui/material";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import {scrollTo} from "@lib/smooth-scroll";

const useIntersectionObserver = (dependencies: unknown[], setActiveId: Dispatch<SetStateAction<string | undefined>>) => {
    const headingElementsRef = useRef<Record<string, IntersectionObserverEntry>>({});
    useEffect(() => {
        const callback: IntersectionObserverCallback = (headings: IntersectionObserverEntry[]) => {
            headingElementsRef.current = headings.reduce(
                (map, headingElement) => {
                    map[headingElement.target.id] = headingElement
                    return map
                },
                headingElementsRef.current
            )

            const visibleHeadings: IntersectionObserverEntry[] = [];
            Object.keys(headingElementsRef.current).forEach((key) => {
                const headingElement = headingElementsRef.current?.[key]
                if (headingElement.isIntersecting) {
                    visibleHeadings.push(headingElement)
                }
            })

            const getIndexFromId = (id: string) =>
                headingElements.findIndex((heading) => heading.id === id)

            if (visibleHeadings.length === 1) {
                setActiveId(visibleHeadings[0].target.id);
            } else if (visibleHeadings.length > 1) {
                const sortedVisibleHeadings = visibleHeadings.sort(
                    (a, b) => getIndexFromId(a.target.id) - getIndexFromId(b.target.id)
                )
                setActiveId(sortedVisibleHeadings[0].target.id)
            }
        };

        const observer = new IntersectionObserver(callback, {
            rootMargin: "0px 0px -40% 0px",
        });

        const headingElements = Array.from(document?.querySelectorAll("h2, h3"));

        headingElements.forEach((element) => observer.observe(element));

        return () => {
            observer.disconnect()
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setActiveId, ...dependencies]);
};

type HeadingEntry = {
    id: string,
    title: string
    level: 1 | 2 | 3 | 4 | 5
    items: HeadingEntry[]
}

const getNestedHeadings = (headingElements: HTMLElement[]): HeadingEntry[] => {
    const nestedHeadings: HeadingEntry[] = [];

    headingElements.forEach((heading, _) => {
        const {innerText: title, id} = heading;

        if (heading.nodeName === "H2") {
            nestedHeadings.push({id, title, level: 1, items: []});
        } else if (heading.nodeName === "H3" && nestedHeadings.length > 0) {
            nestedHeadings[nestedHeadings.length - 1].items.push({
                id,
                title,
                level: 2,
                items: [],
            });
        }
    });

    return nestedHeadings;
};

const useHeadingsData = (dependencies: unknown[]) => {
    const [nestedHeadings, setNestedHeadings] = useState<HeadingEntry[]>([]);

    useEffect(() => {
        const headingElements: HTMLElement[] = Array.from(
            document?.querySelectorAll("h2, h3")
        );

        const newNestedHeadings: HeadingEntry[] = getNestedHeadings(headingElements);
        setNestedHeadings(newNestedHeadings);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, dependencies);

    return nestedHeadings;
};

type TableOfContentsProps = React.HTMLAttributes<HTMLDivElement> & {dependencies: unknown[]}

export const TableOfContents = ({dependencies}: TableOfContentsProps ) => {
    const [activeId, setActiveId] = useState<string>()
    const nestedHeadings = useHeadingsData(dependencies)
    useIntersectionObserver(dependencies, setActiveId)

    return (
        <nav
            aria-label="Table of contents"
        >
            {nestedHeadings.length > 0 && <HeadingsList headings={nestedHeadings} activeId={activeId} header='Contents'/>}
        </nav>
    )
};

const HeadingsList = ({headings, activeId, header}: { headings: HeadingEntry[], activeId?: string, header?: string }) => (
        <List
            dense
            disablePadding
            subheader={header ? <ListSubheader disableSticky>{header}</ListSubheader> : undefined}
        >
            {
                headings.map(heading => (
                    <li
                        key={heading.id}
                    >
                        <ListItem
                            button
                            onClick={(e) => handleClick(e, heading.id)}
                            selected={heading.id === activeId}
                            sx={{borderRadius: 1}}
                        >
                            <Box pl={(heading.level-1)*2}>
                                <ListItemText primary={heading.title} primaryTypographyProps={{variant: 'caption'}}/>
                            </Box>
                        </ListItem>
                        {heading.items && heading.items.length > 0 && <HeadingsList headings={heading.items} activeId={activeId}/>}
                    </li>
                ))
            }
        </List>
    )

const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, id: string) => {
    e.preventDefault();
    if (!id) {
        return
    }
    const element = document?.getElementById(id)
    if(element) {
        scrollTo(element)
    }
    // document?.querySelector(`#${id}`)?.scrollIntoView({
    //     behavior: "smooth",
    // });
}