import { ListItemText} from "@mui/material";
import React from "react";
import {NavigationItemProps, NavListItem} from "@components/side-nav/tree-nav/side-nav";
import {NavigationExternalLinkData} from "@lib/nav/link";
import {SideNavIcon} from "@components/side-nav/tree-nav/side-nav-icon";


export const ExternalLinkItem = ({entry, level}: NavigationItemProps<NavigationExternalLinkData>) => (
    <NavListItem
        component='a'
        href={entry.href}
        level={level}
    >
        <SideNavIcon entry={entry}/>
        <ListItemText primaryTypographyProps={{variant: 'body2'}}>
            {entry.label}
        </ListItemText>
    </NavListItem>
)