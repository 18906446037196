import {extname} from "path";

export const joinSlugs = (slugs: (string | undefined)[]): string => (
    slugs.filter(slug => slug !== undefined)
        .join('/')
)

export const splitSlugs = (slugs: string) => (
    slugs.split('/').filter(slug => slug.length > 0)
)

export const isUrlAbsolute = (url: string) => {
    if (url.indexOf('//') === 0) {
        // URL is protocol-relative (= absolute)
        return true
    }
    if (url.indexOf('://') === -1) {
        // URL has no protocol (= relative)
        return false
    }
    if (url.indexOf('.') === -1) {
        // URL does not contain a dot, i.e. no TLD (= relative, possibly REST)
        return false
    }
    if (url.indexOf('/') === -1) {
        // URL does not contain a single slash (= relative)
        return false;
    }
    if (url.indexOf(':') > url.indexOf('/')) {
        // The first colon comes after the first slash (= relative)
        return false;
    }
    if (url.indexOf('://') < url.indexOf('.')) {
        // Protocol is defined before first dot (= absolute)
        return true;
    }
    // Anything else must be relative
    return false
}

export const isUrlRelative = (url: string) => (
    !isUrlAbsolute(url)
)

export const stripFilenameExtension = (filename: string) => (
    filename.lastIndexOf('.') >= 0 ? filename.substr(0, filename.lastIndexOf('.')) : filename

)
export const getFilenameExtension = (filename: string) => extname(filename)

export const isParentOrEqual = (parentSlug: string, childSlug: string) => (
    childSlug.startsWith(parentSlug)
)

