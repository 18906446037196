import {ListItemIcon} from "@mui/material";
import React from "react";
import {DocIcon} from "@components/icon/doc-icon";
import {NavigationItemData} from "@lib/nav/link";
import {styled} from "@mui/material";

const Root = styled(ListItemIcon)(({theme}) => ({
    color: theme.palette.primary.main,
    minWidth: theme.spacing(4),
}))

export const SideNavIcon = ({entry}: { entry: NavigationItemData }) => (
    <Root>
        {<DocIcon text={entry.label} noFallback/>}
    </Root>
)