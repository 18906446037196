import List from "@mui/material/List";
import React from "react";
import {compareLinks, NavigationData, NavigationFolderData, NavigationItemData} from "@lib/nav/link";
import {FolderItem} from "@components/side-nav/tree-nav/folder-item";
import {InternalLinkItem} from "@components/side-nav/tree-nav/internal-link-item";
import ListSubheader from "@mui/material/ListSubheader";
import {ExternalLinkItem} from "@components/side-nav/tree-nav/external-link-item";
import { ListItemButton, ListItemButtonTypeMap, styled} from "@mui/material";
import {ExtendButtonBase} from "@mui/material/ButtonBase";

type AdditionalListItemProps = {
    level: number
}

type NavListItemProps = ListItemButtonTypeMap<AdditionalListItemProps>

export const NavListItem = styled(ListItemButton, {
    shouldForwardProp: (prop) => prop !== 'level',
})<AdditionalListItemProps>(({theme, level}) => ({
    borderRadius: theme.shape.borderRadius,
    paddingLeft: theme.spacing(3 + level * 2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
})) as unknown as ExtendButtonBase<NavListItemProps>

export const SideNav = ({navFolder, currentSlug}: NavigationData) => {
    return (
        <NavigationList
            title={navFolder.label}
            entry={navFolder}
            currentSlug={currentSlug}
            level={0}
        />
    )
}

export type NavigationItemProps<N extends NavigationItemData = NavigationItemData> = {
    entry: N
    currentSlug: string
    level: number
}

export const NavigationList = ({entry, currentSlug, level, title,...listProps}: NavigationItemProps<NavigationFolderData> & {title?:string}) => {
    return (
        <List
            subheader={
                <ListSubheader
                    component="div"
                >
                    {title}
                </ListSubheader>
            }
            {...listProps}
            component="nav"
            disablePadding
        >
            {entry.children.sort(compareLinks)?.map(child => (
                <NavigationItem key={child.uid} entry={child} currentSlug={currentSlug} level={level}/>
            ))
            }
        </List>
    )
}

export const NavigationItem = ({entry, ...props}: NavigationItemProps) => {
    switch (entry.type) {
        case "Folder":
            return <FolderItem entry={entry} {...props}/>
        case "InternalLink":
            return <InternalLinkItem entry={entry} {...props}/>
        case "ExternalLink":
            return <ExternalLinkItem entry={entry} {...props}/>
    }
}

export default SideNav