import hljs from 'highlight.js';

// Languages
import groovy from 'highlight.js/lib/languages/groovy';
import javascript from 'highlight.js/lib/languages/javascript';
import xml from 'highlight.js/lib/languages/xml';
import json from 'highlight.js/lib/languages/json';
import shell from 'highlight.js/lib/languages/shell';

import {useEffect} from "react";

hljs.registerLanguage('javascript', javascript);
hljs.registerLanguage('groovy', groovy);
hljs.registerLanguage('xml', xml);
hljs.registerLanguage('json', json);
hljs.registerLanguage('shell', shell);

export const useSyntaxHighlighter = (dependencies: unknown[]) => {
    useEffect(()=> {
        // TODO select element with ref
        hljs.highlightAll()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, dependencies)
}