import {Theme} from "@mui/material/styles";
import {
    PFX_DARK_BLUE,
    PFX_GREEN,
    PFX_LIGHT_BLUE,
    PFX_ORANGE, PFX_UNITY_DARK_BLUE
} from "@lib/styles/color-constants";

// TODO make it global?
export const hljsMixin = (theme: Theme) => ({
    '& .hljs': {
        backgroundColor: 'inherit',
        color: 'inherit',
    },
    '& .hljs-strong, &.hljs-emphasis': {
        color: 'inherit',
    },
    '& .hljs-bullet, & .hljs-quote, & .hljs-link, & .hljs-number, & .hljs-regexp, & .hljs-literal': {
        color: theme.palette.mode === 'light' ? '#808080' : PFX_LIGHT_BLUE,
    },
    '& .hljs-code, & .hljs-selector-class': {
        color: theme.palette.mode === 'light' ? PFX_UNITY_DARK_BLUE : PFX_GREEN,
    },
    '& .hljs-emphasis': {
        fontStyle: 'italic',
    },
    '& .hljs-selector-tag, & .hljs-section,& .hljs-attribute, & .hljs-name, & .hljs-variable': {
    },
    '& .hljs-keyword': {
        color: theme.palette.mode === 'light' ? PFX_DARK_BLUE : PFX_ORANGE,
        fontWeight: theme.palette.mode === 'light' ? theme.typography.fontWeightBold : theme.typography.fontWeightRegular,
    },
    '& .hljs-params': {
        color: 'inherit',
    },

    '& .hljs-string': {
        color: theme.palette.mode === 'light' ? '#008000' : PFX_GREEN,
    },

    '& .hljs-subst, & .hljs-type, & .hljs-built_in, & .hljs-builtin-name, & .hljs-symbol, & .hljs-selector-id, & .hljs-selector-attr, & .hljs-selector-pseudo, & .hljs-template-tag, & .hljs-template-variable, & .hljs-addition': {
        color: theme.palette.mode === 'light' ? PFX_UNITY_DARK_BLUE : '#e0c46c',
    },

    '& .hljs-comment, & .hljs-deletion, & .hljs-meta': {
        color: theme.palette.mode === 'light' ? '#808080' : '#7f7f7f',
    },
})