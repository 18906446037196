import {LayoutProps} from "@lib/content-component/layout-props";
import React from "react";
import {Box} from "@mui/material";
import ThemeSwitch from "@components/theme-switch/theme-switch";
import {GenericLayout} from "@components/layout/generic-layout";

const StandardLayout = ({children, ...props}: LayoutProps) => (
    <GenericLayout {...props} fixedMenu={<StandardFixedMenu/>}>
        {children}
    </GenericLayout>
)

const StandardFixedMenu = () => {
    return (
        <Box display='flex' alignItems='center'>
            <ThemeSwitch sx={{alignSelf: 'stretch'}}/>
        </Box>
    )
}

export default StandardLayout